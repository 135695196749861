<template>
  <b-card :title="tableTitle">
    <div class="mb-2">
      <!-- filter -->
      <b-col md="6">
        <slot name="filter"></slot>
      </b-col>
      <div class="d-flex justify-content-between flex-wrap">
        
        <div>
          <b-button
            v-if="creates == 'true'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-0"
            @click="gotoCreatePage()"
          >
            Tambah
          </b-button>
           <b-button
            v-if="back_indicator == 'true'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'indicator_datas' }"
          >
            Kembali
          </b-button>
        </div>
        <!-- filter -->
        <b-form-group
          label="Cari"
          label-cols-sm="2"
          label-align-sm="right"
          label-size=""
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="8">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="'Cari '"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Hapus
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :sort-direction="sortDirection"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tableIndicator"
    >
      <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>

      <template #cell(status_active)="data">
        {{
          data.item.account && data.item.account.verified == true
            ? "Verified"
            : "Not Verified"
        }}
      </template>

       <template #cell(use_district_income)="data">
         <span class="text-capitalize">{{data.item.use_district_income}}</span>
      </template>

      <template #cell(created_at)="data">
         <span class="text-capitalize">{{data.item.created_at | moment("d/M/YYYY")}}</span>
      </template>

      <!-- A virtual column -->
      <template #cell(Aksi)="data">
        
        <div style="min-width: 120px">
          <div>
            <b-form-checkbox
              v-if="is_priority == 'true'"
              :checked="data.item.is_priority == true"
              v-b-popover.hover.bottom="'Priority'"
              class="custom-control-primary float-sm-left"
              name="check-button"
              @change="confirmSetPrity(data.item.id)"
              switch
              style="margin-top:5px;"
            >
              <span class="switch-icon-left">
                <feather-icon icon="EyeIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="EyeOffIcon" />
              </span>
            </b-form-checkbox>
          </div>
           <b-dropdown right text="Menu">
              <b-dropdown-item v-if="shows == 'true'" @click="gotoShowPage(data.item.id)">Lihat</b-dropdown-item>
              <b-dropdown-item v-if="updates == 'true'" @click="gotoEditPage(data.item.id)">Edit</b-dropdown-item>
              <b-dropdown-item v-if="deletes == 'true'" @click="confirmText(data.item.id)">Hapus</b-dropdown-item>
              <b-dropdown-item v-if="legend == 'true'" @click="gotoLegendPage(data.item.id)">Setting Legend</b-dropdown-item>
              <b-dropdown-item v-if="data_event == 'true'" @click="gotoEventPage(data.item.id)">Setting Event</b-dropdown-item>
              <b-dropdown-item v-if="data_highlight == 'true'" @click="gotoHighlightPage(data.item.id)">Setting Highlight</b-dropdown-item>
              <b-dropdown-item v-if="data_highlight == 'true'" v-b-modal.modal-default @click="gotoUploadZip(data.item.id)">Upload Zip</b-dropdown-item>
          </b-dropdown>
         
        </div>
      </template>
    </b-table>
    <span v-if="totalRows == 1" style="margin: 1rem"></span>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <!-- <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
        >
            <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            />
        </b-form-group> -->

      <b-form-group
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="onTableCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <b-modal
        id="modal-default"
        ref="my-modals"
        hide-footer
        centered
        title="Upload Zip Indicator"
      >
       <b-overlay
          :show="show"
          rounded="sm"
        >
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(uploadZip)">
            <b-row>
               <b-col md="12">
                <ValidationProvider
                  name="File"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="File" label-for="mc-csv-data">
                    <b-form-file
                      v-model="tamp_file"
                      @change="csv_change"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-left"
              >
                Simpan
              </b-button>
           </b-form>
        </ValidationObserver>
          </b-overlay>
      </b-modal>
    </b-card-body>
  </b-card>
</template>
<style>
.b-popover{
  font-size: 10px;
}

.table-responsive {
    display: block;
    min-height: 300px;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
</style>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
  BRow,
  BFormFile,
  BOverlay
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";

import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: [
    "tableTitle",
    "apiUrl",
    "perPage",
    "currentPage",
    "items",
    "fields",
    "totalRows",
    "apiBase",
    "creates",
    "updates",
    "deletes",
    "shows",
    "search",
    "is_priority",
    "data_event",
    "data_highlight",
    "legend",
    "back_indicator"
  ],
  components: {
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    BFormFile,
    BOverlay
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-popover": VBPopover,
  },
  data() {
    return {
      errors: {},
      onTablePerPage: this.$props.perPage,
      onTableCurrentPage: this.$props.currentPage,
      pageOptions: [1, 10, 25, 50, 100],
      filter: null,
      sortDirection: "asc",
      sortBy: "",
      sortDesc: false,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      model: {},
      id_ind:null,
      files:null,
      tamp_file:null,
      show: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    let _ = this;
    // Set the initial number of items
    EventBus.$on("changeValue", () => {
      _.$refs.tableIndicator.refresh();
    });
  },
  methods: {
    csv_change(e) {
      let vm = this;
      this.files = e.target.files[0];
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(filteredItems);
    },
    changePerPage() {
      this.$emit("changePerPage", this.onTablePerPage);
    },
    gotoEditPage(id) {
      let _ = this;
      window.location = "/" + _.$props.apiUrl + "s/" + id;
    },
    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/show");
    },
    gotoCreatePage() {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/add");
    },
    gotoLegendPage(id) {
      let _ = this;
      // console.log(_.$props.apiUrl + "s/" + id + "/legend");
      
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/legend");
    },
    gotoEventPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/event");
    },
    gotoHighlightPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/highlight");
    },
    confirmSetPrity(id)
    {
      let _ = this
      // console.log(_.$props.apiUrl);
      // return;
        axios
            .post("/" + _.$props.apiUrl + "/is_priority/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil set Priority",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableIndicator.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak berhasil set Priority",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
    },
    gotoUploadZip(id){
      this.id_ind = id
    },
    uploadZip()
    {
      // console.log( this.id_ind, this.files )
      this.show = true
      let _ = this
      let url  = 'indicator/uploadcsv/' +  this.id_ind
      let formData = new FormData();
        formData.append("file", _.files);
        axios
          .post(url, formData)
          .then((res) => {
            this.files = null
            this.tamp_file = null
            this.show = false
            this.$refs['my-modals'].hide()
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
    },
    confirmText(id) {
      let _ = this
      console.log(_.$props.apiUrl);
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/" + _.$props.apiUrl + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableIndicator.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
  watch: {
    onTableCurrentPage: function (newValue) {
      let _ = this;
      _.$emit("changeCurrentPage", newValue);
    },
    items: function (newValue) {
      console.log(newValue);
      _.totalRows = _.items.length;
    },
  },
};
</script>
